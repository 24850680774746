define("ember-pagination-links/templates/components/pagination-links", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showFirstAndLast}}
    <a class="pagination-links-first {{if this.firstDisabled 'disabled'}}" {{action "goToPage" 1}}>{{this.firstPageIcon}}</a>
  {{/if}}
  
  <a class="pagination-links-prev {{if this.prevDisabled 'disabled'}}" {{action "goToPrevPage"}}>{{this.prevPageIcon}}</a>
  
  {{#if this.showFirstEllipsis}}
    <span class="pagination-links-ellipsis">{{this.ellipsisIcon}}</span>
  {{/if}}
  
  {{#each this.pagesBeforeCurrent as |page|}}
    <a class="pagination-links-page" {{action "goToPage" page}}>{{page}}</a>
  {{/each}}
  
  <a class="pagination-links-current">{{this.currentPage}}</a>
  
  {{#each this.pagesAfterCurrent as |page|}}
    <a class="pagination-links-page" {{action "goToPage" page}}>{{page}}</a>
  {{/each}}
  
  {{#if this.showLastEllipsis}}
    <span class="pagination-links-ellipsis">{{this.ellipsisIcon}}</span>
  {{/if}}
  
  <a class="pagination-links-next {{if this.nextDisabled 'disabled'}}" {{action "goToNextPage"}}>{{this.nextPageIcon}}</a>
  
  {{#if this.showFirstAndLast}}
    <a class="pagination-links-last {{if this.lastDisabled 'disabled'}}" {{action "goToPage" this.lastPage}}>{{this.lastPageIcon}}</a>
  {{/if}}
  
  */
  {
    "id": "SCzwvpq4",
    "block": "[[[41,[30,0,[\"showFirstAndLast\"]],[[[1,\"  \"],[11,3],[16,0,[29,[\"pagination-links-first \",[52,[30,0,[\"firstDisabled\"]],\"disabled\"]]]],[4,[38,1],[[30,0],\"goToPage\",1],null],[12],[1,[30,0,[\"firstPageIcon\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[11,3],[16,0,[29,[\"pagination-links-prev \",[52,[30,0,[\"prevDisabled\"]],\"disabled\"]]]],[4,[38,1],[[30,0],\"goToPrevPage\"],null],[12],[1,[30,0,[\"prevPageIcon\"]]],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showFirstEllipsis\"]],[[[1,\"  \"],[10,1],[14,0,\"pagination-links-ellipsis\"],[12],[1,[30,0,[\"ellipsisIcon\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"pagesBeforeCurrent\"]]],null]],null],null,[[[1,\"  \"],[11,3],[24,0,\"pagination-links-page\"],[4,[38,1],[[30,0],\"goToPage\",[30,1]],null],[12],[1,[30,1]],[13],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[10,3],[14,0,\"pagination-links-current\"],[12],[1,[30,0,[\"currentPage\"]]],[13],[1,\"\\n\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"pagesAfterCurrent\"]]],null]],null],null,[[[1,\"  \"],[11,3],[24,0,\"pagination-links-page\"],[4,[38,1],[[30,0],\"goToPage\",[30,2]],null],[12],[1,[30,2]],[13],[1,\"\\n\"]],[2]],null],[1,\"\\n\"],[41,[30,0,[\"showLastEllipsis\"]],[[[1,\"  \"],[10,1],[14,0,\"pagination-links-ellipsis\"],[12],[1,[30,0,[\"ellipsisIcon\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[11,3],[16,0,[29,[\"pagination-links-next \",[52,[30,0,[\"nextDisabled\"]],\"disabled\"]]]],[4,[38,1],[[30,0],\"goToNextPage\"],null],[12],[1,[30,0,[\"nextPageIcon\"]]],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showFirstAndLast\"]],[[[1,\"  \"],[11,3],[16,0,[29,[\"pagination-links-last \",[52,[30,0,[\"lastDisabled\"]],\"disabled\"]]]],[4,[38,1],[[30,0],\"goToPage\",[30,0,[\"lastPage\"]]],null],[12],[1,[30,0,[\"lastPageIcon\"]]],[13],[1,\"\\n\"]],[]],null]],[\"page\",\"page\"],false,[\"if\",\"action\",\"each\",\"-track-array\"]]",
    "moduleName": "ember-pagination-links/templates/components/pagination-links.hbs",
    "isStrictMode": false
  });
});